import IMAGE_SKIN from "../images/Skin2.png"
import IMAGE_EYES from "../images/Eye2.png"
import IMAGE_LIPS from "../images/Lips.png"
import IMAGE_FORHEAD from "../images/Forehead.png"
import IMAGE_NEK from "../images/Nek3.png"
import IMAGE_TOTALFACE from "../images/TotalFace5.png"
import IMAGE_RHINOPLASTY from "../images/Rhinoplasty2.png"
import IMAGE_EAR from "../images/Ears2.png"
import IMAGE_HANDS from "../images/Hands.png"
import IMAGE_CORRECT from "../images/correct5.png"
import IMAGE_GROUPBACKGROUND from "../images/groupTreatmentsFilterB20.jpg"

import IMAGE_FACIALMETHOD from "../images/facialAesthetics.png"
import IMAGE_FACE_NOSE_SIDE from "../images/face-nose-sideprofile.png"
import IMAGE_SUSAN from "../images/SusanAboutUS.jpg"
import IMAGE_CALLUM from "../images/CallumAboutUS.jpg"
import IMAGE_HADE from "../images/hadev4.jpg"
import IMAGE_SUSAN_WEB from "../images/SusanSchmidtWeb.jpg"
import IMAGE_CALLUM_WEB from "../images/CallumFarisWeb.jpg"
import IMAGE_HADE_WEB from "../images/hadev4.jpg"

import IMAGE_3D_CONSULTATION from "../images/3d-face-surgery.jpg"
import IMAGE_3D_SURGERY from "../images/3d-face-surgery-consultation.jpg"
import IMAGE_SELFIES from "../images/selfies.png"
import IMAGE_3D_REG from "../images/register-plastic-surgery-consultation.png"

const Images = {
  IMAGE_SKIN,
  IMAGE_EYES,
  IMAGE_LIPS,
  IMAGE_FORHEAD,
  IMAGE_NEK,
  IMAGE_TOTALFACE,
  IMAGE_RHINOPLASTY,
  IMAGE_EAR,
  IMAGE_HANDS,
  IMAGE_CORRECT,
  IMAGE_GROUPBACKGROUND,

  IMAGE_FACIALMETHOD,
  IMAGE_FACE_NOSE_SIDE,
  IMAGE_SUSAN,
  IMAGE_CALLUM,
  IMAGE_HADE,

  IMAGE_SUSAN_WEB,
  IMAGE_CALLUM_WEB,
  IMAGE_HADE_WEB,
  IMAGE_3D_CONSULTATION,
  IMAGE_3D_SURGERY,
  IMAGE_SELFIES,
  IMAGE_3D_REG,
}

export default Images

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import backgroundImage from "../images/3d-face-surgery.jpg"
import LineTitle from "../components/LineTitle"
import Images from "../styles/Images"
// import sale from "../images/sale2.png"
import sale from "../images/150e.png"
import Img from "gatsby-image"
import { graphql } from "gatsby"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  book3d: {
    color: "#fff!important",
    fontSize: "14px!important",
    backgroundColor: "#929B91",
    backgroundColor: "red",
    borderRadius: "40px!important",
    padding: "1.0em 4em!important",
    marginLeft: "3rem!important",
    textDecoration: "none!important",
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "24px!important",
      marginBottom: "24px!important",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "1800px",
    alignItems: "center",
    paddingTop: "100px",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  bannerGrid: {
    background: "white",
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  pageTitle: {
    paddingTop: "80px",
  },
  tooLongtitle: {
    color: "#575757",
    ["@media (max-width:600px)"]: {
      fontSize: "2.4rem",
    },
  },
  tooLongSubheader: {
    color: "#575757",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  lineContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1rem",
    borderLeft: "3px solid #565A56",
    width: "100%",
  },
  threeD: {
    minWidth: "100%",
    height: 600,
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
  selfies: {
    // maxWidth: "50%",
    paddingTop: 80,
    paddingBottom: 40,
    width: 200,
    // maxHeight: 200,
    // height: 500,
    alignself: "center",
    ["@media (max-width:600px)"]: {},
  },
  bodyHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    // fontWeight: "lighter",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  bodyHeader2: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    // fontWeight: "lighter",
    fontWeight: 300,
    maxWidth: 350,
    color: "#575757",
  },
  root: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  root2: {
    flexGrow: 1,
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 40,
  },
  bodyText2: {
    textAlign: "left",
    color: "#575757",
    maxWidth: 350,
    whiteSpace: "pre-line",
    fontSize: "1.1rem",
    paddingBottom: 20,
  },
  instructionContainer: {
    background: "white",
    paddingBottom: 70,
  },
  reg: {
    paddingTop: 160,
    paddingBottom: 80,
    width: 200,
    // maxWidth: 400,
    ["@media (max-width:600px)"]: {
      paddingTop: 60,
    },
  },
  book3d2: {
    display: "inline-block",
    color: "#fff!important",
    fontSize: "14px!important",
    backgroundColor: "red",
    borderRadius: "40px!important",
    padding: "1.0em 4em!important",
    justifyContent: "flex-start",
    alignself: "flex-start",
    textDecoration: "none!important",
    // paddingBottom: 20,
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "24px!important",
      // marginBottom: "24px!important",
    },
  },
  mobileHeaderImage: {
    backgroundImage: "none",
    ["@media (max-width:600px)"]: {
      backgroundImage: `url(${Images.IMAGE_3D_SURGERY})`,
      backgroundRepeat: "no-repeat",
      height: 480,
      backgroundSize: "contain",
    },
  },
  sale: {
    visivility: "hidden",
    // width: 70,
    // display: "block",
    marginLeft: 50,
  },
}))

const ThreeDConsultation = ({ intl, data, location }) => {
  const banner = data.bannerImage.childImageSharp.fluid
  const selfies = data.selfies.childImageSharp.fluid
  const register = data.register.childImageSharp.fluid
  const classes = useStyles()
  const t = (id) => intl.formatMessage({ id })

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "3d.SEOtitle" })}
        description={intl.formatMessage({ id: "3d.SEO" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <Grid container spacing={0} className={classes.bannerGrid}>
          <Grid item xs={12} sm={4} className={classes.bannerLeft}>
            {/* <img
              src={Images.IMAGE_3D_SURGERY}
              alt="Face scanned"
              className={classes.threeD}
            ></img> */}
            <Img fluid={banner} alt="Face scanned" className={classes.threeD} />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.mobileHeaderImage}>
            <Container>
              <LineTitle
                header={t("3d.title")}
                subheader={t("3d.subtitle")}
                className={classes.pageTitle}
                titleStyle={classes.tooLongtitle}
                lightDecoLine
                subHeaderStyle={classes.tooLongSubheader}
              />
              <Button
                className={classes.book3d}
                data-leadbox-popup="yHrncaKgjxSZBJPDmdbYU9"
                data-leadbox-domain="kooldigital.lpages.co"
              >
                Book 3D
              </Button>
              {/* <img src={sale} alt="sale price" className={classes.sale}></img> */}
            </Container>
          </Grid>
        </Grid>
        <div className={classes.root}>
          <Grid container spacing={12}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("3d.introTitle")}
              </Typography>
              <p className={classes.bodyText}>{t("3d.introText")}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              align="center"
              className={classes.selfies}
            >
              <Img
                fluid={selfies}
                alt="Face selfie"
                style={{ maxWidth: 400 }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.root2}>
          <Grid container spacing={12} className={classes.instructionContainer}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h4"
                className={classes.bodyHeader2}
              >
                {t("3d.instructionTitle")}
              </Typography>
              <p className={classes.bodyText2}>{t("3d.instructionText")}</p>
              <Button
                className={classes.book3d2}
                data-leadbox-popup="yHrncaKgjxSZBJPDmdbYU9"
                data-leadbox-domain="kooldigital.lpages.co"
              >
                Book 3D
              </Button>
              <div
                style={{
                  width: "90px",
                  height: 20,
                  display: "inline-block",
                }}
              ></div>
              <div
                style={{
                  width: "90px",
                  height: 20,
                  display: "inline-block",
                }}
              ></div>
            </Grid>
            <Grid item xs={12} sm={6} align="center" className={classes.reg}>
              <Img
                fluid={register}
                alt="how to register"
                style={{ maxWidth: 300 }}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Layout>
  )
}

export default injectIntl(ThreeDConsultation)

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { eq: "3d-face-surgery-consultation.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    selfies: file(relativePath: { eq: "selfies.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    register: file(
      relativePath: { eq: "register-plastic-surgery-consultation.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
